export const PAYMENT_NATURE_REFUND = "refund";
export const PAYMENT_NATURE_PAYMENT = "payment";

export const PAYMENT_NATURES = [
  { id: PAYMENT_NATURE_PAYMENT, name: "Paiement" },
  { id: PAYMENT_NATURE_REFUND, name: "Remboursement" },
];

export const PAYMENT_MODE_CREDIT_CARD = "credit_card";
export const PAYMENT_MODE_BANK_TRANSFER = "bank_transfer";
export const CHECK = "check";
export const CASH = "cash";
export const PREPAYED_ONLINE = "prepayed_online";
export const PAYED_ONLINE = "payed_online";

export const PAYMENT_MODES = [
  { id: PAYMENT_MODE_CREDIT_CARD, name: "Carte bancaire" },
  { id: CHECK, name: "Chèque" },
  { id: CASH, name: "Espèces" },
  { id: PREPAYED_ONLINE, name: "Prépayé en ligne" },
  { id: PAYED_ONLINE, name: "Payé en ligne" },
  { id: PAYMENT_MODE_BANK_TRANSFER, name: "Virement bancaire" },
];

export const PAYMENT_MODES_NEEDING_EMPLOYEE = [PAYMENT_MODE_CREDIT_CARD, CHECK, CASH];
export const TVA_RATE = 0.2;
