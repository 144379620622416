import React from "react";

import { DateInput, SelectInput, SimpleForm, TextInput } from "react-admin";

import { Box, Typography } from "@material-ui/core";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

import moment from "moment";
import { toastr } from "react-redux-toastr";

import { BinaryValidateToolbar } from "components/FormToolbars";
import Modal from "components/Modal";

import {
  PAYMENT_MODES,
  PAYMENT_MODES_NEEDING_EMPLOYEE,
  PAYMENT_MODE_BANK_TRANSFER,
  PAYMENT_MODE_CREDIT_CARD,
} from "constants/payment";

import { INTERVENTION } from "pages/Interventions/components/constants";
import { PAYMENT, REFUND } from "pages/customerFiles/components/CustomerFileBillingQuotes/services/constants";

import { toCents } from "utils/price";
import { getEmployeeFullName, repairmenSortedList } from "utils/repairmenSortedList";

const AddPaymentModal = ({
  isAddPaymentModalOpen,
  handleClosePaymentModal,
  handleValidatePayment,
  employees,
  visits,
  amountPaymentIntent,
  isPayment,
}) => {
  const handleSubmit = (values) => {
    try {
      const amountInCents = toCents(values.amount);
      const adjustedAmount = isPayment ? amountInCents : -amountInCents;

      const paymentDate = isPayment ? moment(values.date).locale("fr").toISOString() : undefined;

      if (isPayment && PAYMENT_MODES_NEEDING_EMPLOYEE.includes(values.payment_type) && !values.employee) {
        toastr.error(
          "Attention",
          "Le technicien est obligatoire pour les paiements par chèque, espèces ou carte bancaire"
        );
        return;
      }
      handleValidatePayment(
        isPayment ? PAYMENT : REFUND,
        adjustedAmount,
        values.payment_type,
        values.employee,
        paymentDate,
        values.payment_partner,
        values.payment_reference
      );
    } catch (error) {
      console.error("Erreur lors du traitement du paiement:", error.message);
    }
  };
  const getDefaultValues = () => {
    const interventions = visits.filter(function (visits) {
      return visits.visit_status === INTERVENTION;
    });

    if (interventions.length > 0) {
      const lastVisit =
        interventions &&
        interventions.reduce((prev, current) =>
          prev.timeslot && current.timeslot && prev.timeslot.beginning > current.timeslot.beginning ? prev : current
        );
      if (lastVisit.timeslot) {
        return {
          defaultTech: lastVisit.assigned_repairman,
          defaultDate: moment(lastVisit.timeslot.beginning).format("YYYY-MM-DD"),
        };
      }
    }
    return { defaultTech: null, defaultDate: moment().locale("fr") };
  };

  const isRefund = () => !isPayment;

  const pricePositive = (value) => {
    if (value < 0) {
      return "Le prix doit être positif !";
    }
    if (!value) {
      return "Le prix est obligatoire !";
    }
    return undefined;
  };
  const { defaultTech, defaultDate } = getDefaultValues();

  return (
    <Modal
      isOpen={isAddPaymentModalOpen}
      handleCloseModal={handleClosePaymentModal}
      modalClassName={`modal-small-content`}
    >
      <Box width={570} mb={4}>
        <Box textAlign="center" py={4}>
          <Typography variant="h2">{isRefund() ? "Valider le remboursement" : "Valider le paiement"}</Typography>
        </Box>
        <SimpleForm
          save={handleSubmit}
          handleSubmit={handleSubmit}
          initialValues={{
            amount: amountPaymentIntent,
            payment_type: isPayment ? PAYMENT_MODE_CREDIT_CARD : PAYMENT_MODE_BANK_TRANSFER,
            payment_partner: "",
            payment_reference: "",
            date: defaultDate,
            employee: defaultTech,
          }}
          toolbar={
            <BinaryValidateToolbar
              size="medium"
              variant="contained"
              label={`${isPayment ? "Valider le paiement" : "Valider le remboursement"}`}
              onSave={handleSubmit}
              handleClose={handleClosePaymentModal}
            />
          }
        >
          <Box width="328px" margin="auto">
            <TextInput
              source="amount"
              validate={[pricePositive]}
              label={isRefund() ? "Montant remboursé" : "Montant payé"}
              InputProps={{
                endAdornment: <EuroSymbolIcon />,
              }}
              type="number"
              variant="outlined"
              fullWidth
            />
            <SelectInput
              source="payment_type"
              label="Moyen de paiement"
              fullWidth
              variant="outlined"
              choices={PAYMENT_MODES}
              optionText="name"
            />

            {isRefund() && (
              <>
                <TextInput source="payment_partner" label={"Prestataire de paiement"} variant="outlined" fullWidth />
                <TextInput source="payment_reference" label={"Référence prestataire"} variant="outlined" fullWidth />
              </>
            )}
            {!isRefund() && (
              <>
                <SelectInput
                  source="employee"
                  label="Technicien"
                  fullWidth
                  variant="outlined"
                  choices={repairmenSortedList(employees)}
                  optionText={getEmployeeFullName}
                />
                <DateInput source="date" variant="outlined" fullWidth />
              </>
            )}
          </Box>
        </SimpleForm>
      </Box>
    </Modal>
  );
};

export default AddPaymentModal;
