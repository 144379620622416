import React from "react";

import { Box, styled } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";

import {
  Attachment,
  Blocage,
  Default,
  Diagnostic as DiagnosticType,
  Resolution,
  Symptom,
  UnfulfilledReplacement,
} from "types/diagnostic";

import ColorChip from "components/atoms/ColorChip";
import Divider from "components/atoms/Divider";
import { Body } from "components/atoms/Typography";
import CollapsibleBlock from "components/molecules/CollapsibleBlock";

import { useDiagnosticSparePartFittings } from "utils/hooks/useDiagnosticSparePartFittings";

import InformativeBlock from "../InformativeBlock";
import { Variant } from "../InformativeBlock/InformativeBlock";
import ProductTitle from "../ProductTitle/ProductTitle";
import DiagnosticItems from "./components/DiagnosticItems";
import DiagnosticPictures from "./components/DiagnosticPictures";

interface DiagnosticProps {
  diagnostic: DiagnosticType;
}

const StyledBox = styled(Box)(({ theme }: any) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Diagnostic: React.FC<DiagnosticProps> = ({ diagnostic }) => {
  const getSpecificDiagItem = (type: string, context?: string) =>
    diagnostic.diagnostic_items.filter(
      (item: any) => item.type === type && (context ? item.context === context : !item.context)
    );

  const spare_part_fittings = useDiagnosticSparePartFittings(diagnostic);

  const renderInformativeBlock = (title: string, items: any[], variant: Variant, icon?: React.ReactNode) => {
    const pluralizedTitle =
      items.length > 1
        ? `${title
            .split(" ")
            .map((word) => word + "s")
            .join(" ")}`
        : title;

    return (
      <StyledBox>
        <InformativeBlock
          title={pluralizedTitle}
          content={<DiagnosticItems items={items} />}
          variant={variant}
          icon={icon}
        />
      </StyledBox>
    );
  };

  const renderColorChip = (label: string, hexColor: string) => {
    return (
      <Box width="fit-content" mt={1}>
        <ColorChip label={label} hexColor={hexColor} />
      </Box>
    );
  };

  return (
    <CollapsibleBlock size="sm" openByDefault={false} title={<ProductTitle diagnostic={diagnostic} />}>
      {getSpecificDiagItem("blocage", "impossible_intervention").length > 0 &&
        renderInformativeBlock(
          "Problème",
          getSpecificDiagItem("blocage", "impossible_intervention") as Blocage[],
          "default",
          <CancelIcon />
        )}
      {renderInformativeBlock("Symptôme", getSpecificDiagItem("symptom") as Symptom[], "white")}
      {renderInformativeBlock("Défault", getSpecificDiagItem("default") as Default[], "white")}
      {getSpecificDiagItem("resolution", undefined).length > 0 &&
        renderInformativeBlock("Résolution", getSpecificDiagItem("resolution", undefined) as Resolution[], "success")}
      {getSpecificDiagItem("resolution", "irreparable").length > 0 &&
        renderInformativeBlock(
          "Machine irréparable",
          getSpecificDiagItem("resolution", "irreparable") as Resolution[],
          "error"
        )}

      {diagnostic.diagnostic_items.filter(
        (item): item is UnfulfilledReplacement => item.type === "replacement" && !item.spare_part_fitting
      ).length > 0 &&
        renderInformativeBlock(
          "Pièce nécessaire",
          diagnostic.diagnostic_items.filter(
            (item): item is UnfulfilledReplacement => item.type === "replacement" && !item.spare_part_fitting
          ),
          "warning"
        )}

      {spare_part_fittings.length > 0 && renderInformativeBlock("Pièce posée", spare_part_fittings, "info")}

      <StyledBox>
        <InformativeBlock
          title="Etat de l'appareil à la fin de l'intervention"
          content={
            diagnostic.appliance_is_repaired
              ? renderColorChip("Réparé", "#548052")
              : renderColorChip("En Panne", "#FFA500")
          }
          variant="white"
        />
      </StyledBox>

      {diagnostic.note_for_reopening && diagnostic.note_for_reopening.content !== "" && (
        <StyledBox>
          <InformativeBlock
            title="Note en cas de réouverture"
            content={diagnostic.note_for_reopening.content}
            icon={<InfoIcon />}
            variant="warning"
          />
        </StyledBox>
      )}
      {diagnostic.note_for_next_intervention &&
        (diagnostic.note_for_next_intervention.content !== "" ||
          diagnostic.note_for_next_intervention.book_more_time) && (
          <StyledBox>
            <InformativeBlock
              title="Note pour la prochaine intervention"
              content={
                <>
                  {diagnostic.note_for_next_intervention.content}
                  {diagnostic.note_for_next_intervention.book_more_time && (
                    <Body fontStyle="italic">Un passage long est nécessaire pour la prochaine intervention</Body>
                  )}
                </>
              }
              icon={<InfoIcon />}
              variant="warning"
            />
          </StyledBox>
        )}
      <StyledBox>
        <DiagnosticPictures pictures={getSpecificDiagItem("attachment", undefined) as Attachment[]} />
      </StyledBox>

      <Divider color="grey" />
    </CollapsibleBlock>
  );
};

export default Diagnostic;
